<template>
  <div class="about-side-bar">
    <div class="title">
      {{label1}}<br />{{label2}}
    </div>
    <div class="side-nav">
      <div v-for="(item, index) in navData" :key="index" @click="switchNav(item.class, index)" :class="[currentNav === item.class ? 'active' : '', index + 1 === currentIndex ? 'activePrev' : '', 'nav-item']">
        <div>{{item.name}}</div>
        <img v-if="item.class == currentNav" src="../../assets/img/aboutus/more_active.png" alt="">
        <img v-else src="../../assets/img/aboutus/more.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: 0,
      currentNav: '',
    }
  },
  props: {
    navData: Array,
    label1: String,
    label2: String,
  },
  created() {
    this.currentNav = this.$route.name
    // console.log(this.currentNav)
    // console.log(this.navData)
    this.$emit('pageName', this.currentNav)
    let _this = this
    this.navData.forEach((item, index) => {
      if (item.class == _this.currentNav) {
        _this.currentIndex = index
      }
    }) 
  },
  watch:{
    $route(to){
      this.currentNav = to.name
    }
  },
  methods: {
    // 导航切换
    switchNav(nav, index) {
      this.currentIndex = index
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.currentNav = nav
      if (this.currentNav != nav) {
        this.$emit('loading', true)
      }
      this.$router.push({ name: nav })
      this.$emit('pageName', nav)
    }
  }
}
</script>
<style lang="scss" scoped>
.about-side-bar {
  // position: fixed;
  // top: 5rem;
  width: 3rem;
  height: auto;

  .title {
    width: 100%;
    height: 1.1rem;
    background-image: url(../../assets/img/aboutus/nav.png);
    background-size: 100% 100%;
    font-size: 0.3rem;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0.19rem 0.24rem 0;
    line-height: 1.3;
  }
  .side-nav {
    width: 100%;
    .nav-item {
      background-color: #ffffff;
      width: 100%;
      display: flex;
      height: 0.55rem;
      justify-content: space-between;
      align-items: center;
      font-size: 0.18rem;
      color: #333333;
      box-sizing: border-box;
      padding: 0 0.2rem;
      border-bottom: 1px solid #dcdcdc;
      cursor: pointer;
      &.activePrev {
        border-bottom: 1px solid  rgba(241, 192, 118, 0);
      }
      &:hover {
        background-color: #fff8ec;
        color: #ff6004;
        border-color: rgba(255, 168, 41, .1);
      }
      &.active {
        background-color: #fff8ec;
        color: #ff6004;
        border-color: rgba(255, 168, 41, .1);
        position: relative;
        &::after {
          display: block;
          content: "";
          height: 100%;
          width: 0.04rem;
          background-color: #ff6004;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    img {
      width: 0.06rem;
      height: 0.1rem;
    }
  }
}
</style>